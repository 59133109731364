import styled from 'styled-components';

export const ContactContainer = styled.div.attrs(() => ({
  name: 'Contact'
}))`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100%;
  padding-top: 15px;
  background: transparent;  
  color: ${props => props.theme.colors.light};

  @media screen and (min-width: 768px){
    padding: 15px calc(((100vw - 1170px) / 2));

  }

`
export const ContactContainerTitle = styled.h1`
  padding: 15px 15px;

  font-family: ${props => props.theme.fonts.title};
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 35px;


`
export const ContactWrap = styled.div`
  @media screen and (min-width: 768px){
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    justify-content: space-between;
  }
`

export const ContactContainerTextContent = styled.p`
  font-family: ${props => props.theme.fonts.text};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  padding: 15px;

  span{
    font-weight: 500; 
  }

`
export const LocationMap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 250px;
  
  padding: 15px;

  iframe{
    width: 100%;
    height: 100%;
  }
`

export const ContactContainerFooterContent = styled.footer`
  font-family: ${props => props.theme.fonts.text};
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  text-align: center;

  padding: 15px;
  margin: 15px;

  border-top: 1px dashed ${props => props.theme.colors.primary}; 

  

`
