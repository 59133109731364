import React, { useRef } from 'react';

import { ModalBackground, SchedulingModalContainer, ModalIFrame, ModalCloseButton } from "./styles"

function SchedulingModal({showModal, setShowModal, ...props}){
  const { srcIFrame } = props;
  const bgRef = useRef();
  const closeBtnRef = useRef();

  const closeModal = event => {
    if((bgRef.current === event.target) || (closeBtnRef.current === event.target)){
      setShowModal(false);
      document.body.style.position = '';
    }
  }

  return (
    <>
    {showModal ? (
        <>
        {document.body.style.position = 'fixed'}
        <SchedulingModalContainer>
          <ModalIFrame src={srcIFrame} />
          <ModalCloseButton ref={closeBtnRef} onClick={closeModal}>X</ModalCloseButton>          
        </SchedulingModalContainer>
        <ModalBackground ref={bgRef} onClick={closeModal} />
        </>
    ) : null}
    </>    
  );  
}

export default SchedulingModal;