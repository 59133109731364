import styled from 'styled-components';

export const AboutUsContainer = styled.div.attrs(() => ({
  name: 'AboutUs'
}))`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100%;
  padding-top: 20px;

  background: ${props => props.theme.colors.light};
  color: ${props => props.theme.colors.dark};

  @media screen and (min-width: 768px){
    padding: 0 calc(((100vw - 1170px) / 2));
  }


  h1{
    padding: 30px 15px;
    font-family: ${props => props.theme.fonts.title};
    font-size: 32px;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};

  }
  p{
    padding: 0 15px;
    font-family: ${props => props.theme.fonts.text};
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.colors.dark};

  }
`