import styled from 'styled-components';

import calendar from '../../assets/svg/calendar.svg';

export const YellowBarContainer = styled.div`
  width: 100vw;
  max-width: 100%;
  height: 90px;
  display: none;

  @media screen and (min-width: 768px){

    font-family: ${props => props.theme.fonts.title};

    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: center;

    background: ${props => props.theme.colors.primary};
    
    p{
      display: block;
      color: ${props => props.theme.colors.dark};
      font-weight: 500;
      font-size: 24px;
      line-height: 26px;
      text-align: center;
    }
    
  }
`
export const SchedulingButton = styled.a`
  position: absolute;
  text-decoration: none;
  background-color: ${props => props.theme.colors.background};
  transition: background-color 0.2s;
  width: 200px;
  border-radius: 5px;
  margin-bottom: -85px;
  display: inline-block;
  padding: 10px 5px;

  cursor: pointer;

  display: flex;
  justify-content: space-evenly;
  align-items: center;  

  color: ${props => props.theme.colors.light};
  font-weight: 600;
  font-size: 18px;
  
  &:hover{
    background-color: ${props => props.theme.colors.primary};
  }
`

export const CalendarIcon = styled(calendar)`
  width: 32px;
  height: 32px;

  fill: ${props => props.theme.colors.light};

  margin-right: 5px;
`