import styled from 'styled-components';

export const WhatsAppContactContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: sticky;
  bottom: 0px;
  z-index: 999;
`

export const WhatsAppContactItem = styled.a`
  display: flex;
  position: relative;

  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;

  margin-right: 15px;
  margin-bottom: 15px;
  
  transition: opacity 0.3s;
  
  &:hover{
    opacity: 0.8;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

export const AvecScheduleItem = styled.a`
  padding: 15px;
  border-radius: 5px 5px 0 0;
  width: 180px;
  margin-right: 15px;

  font-family: ${props => props.theme.fonts.title};
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  text-align: center;

  background: ${props => props.theme.colors.primary};
  color: ${props=> props.theme.colors.dark};

  cursor: pointer;

  transition: background-color 0.3s;

  &:hover{
    background: ${props => props.theme.colors.light};
  }
`