import React, { useEffect, useRef, useState } from 'react';
// import useSiteMetadata from '../../hooks/use-site-metadata';

import { BgImage, HeroContainer, ImpactPhrase } from './styles';

import { getImage } from "gatsby-plugin-image";
import useSlideShowImage from '../../hooks/useSlideShowImage';

function Hero({ isMobile }) {
  // const { whatsapp } = useSiteMetadata().social;
  // const messageWhatsApp = "Olá! Gostaria de agendar um atendimento."
  // let linkWhatsapp;
  // if (isMobile) {
  //   linkWhatsapp = `whatsapp://send?phone=${whatsapp}&text=${messageWhatsApp}`
  // }else{
  //   linkWhatsapp = `https://web.whatsapp.com/send?phone=${whatsapp}&text=${messageWhatsApp}`
  // }
  
  
  const useImages = useSlideShowImage();

  const [image, setImage] = useState(0); 
  const timeoutRef = useRef();     
  useEffect(() => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      image === useImages.length-1 ? setImage(0) : setImage(image + 1)
    }, 5000)
    
  })

  return (
    <HeroContainer>
        <ImpactPhrase>
          <p>
            Nós <span>estudamos</span> o seu perfil, <br />
            <span>alteramos</span> o seu visual, <br/>
            <span>impactamos</span> sua <br/> 
            autoestima.
          </p>
          {/* <a 
          href={linkWhatsapp} 
          target="_blank"
          rel="noreferrer"
          >
            <CalendarIcon />
            Agendar um atendimento
          </a> */}
        </ImpactPhrase>

        <BgImage
          image={getImage(useImages[image].node)}
          alt={useImages[image].node.id}
        />   
    </HeroContainer>
  );
}

export default Hero;
