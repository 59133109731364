import styled from 'styled-components';

export const InstagramFeedContainer = styled.div`
  width: 100vw;
  max-width: 100%;
  padding-top: 50px;

  background: ${props => props.theme.colors.light};
`

export const InstagramFeedCarrousel = styled.div`
  display: flex;
  
  overflow-x: auto;
  scroll-snap-type: x mandatory; /* precisa de um elemento dno pai e outro no filho */
  -webkit-overflow-scrolling: touch; //compatibilidade
  scroll-behavior: smooth;

  @media screen and (min-width: 768px){
    flex-wrap: wrap; //or flex-flow: row wrap;
    width: 100%;    
  }
`

export const InstragramFeedCarrouselImage = styled.div`
    flex: none;
    width: 75%;
    
    scroll-snap-align: start;
/* 
    pointer-events: none; */ //tirar eventos do listener

    a {
      display: flex;
      margin: 0;
      padding: 0;

      transition: opacity 0.3s ease-in-out;

      &:hover{
        opacity: 0.8;
      }
    }
    
    @media screen and (min-width: 768px){
      flex: 1 0;
      width: fit-content;
      display: flex;
      object-fit: cover;
      min-width: 350px;
    }
` 