import styled from 'styled-components'

export const Container = styled.main`
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;

  align-items: center;
  width: 100%;
`