import React, { useState } from 'react'
// import { Link } from 'gatsby'
import { Nav, LogoImg, MenuIcon, MenuMobile, MenuMobileMask, MenuItems, HeaderContainer, InstagramIcon, FacebookIcon } from './styles'
import menuData from './menuData'
import useSiteMetadata from '../../hooks/use-site-metadata';

import { Link as ScrollLink } from 'react-scroll'

export default function Header({ isMobile }) {
    const { instagram, facebook } = useSiteMetadata().social;
    // const messageWhatsApp = "Olá, gostaria de marcar um atendimento!"
    // let linkWhatsapp;

    // if (isMobile) {
    //     linkWhatsapp = `whatsapp://send?phone=${whatsapp}&text=${messageWhatsApp}`
    // }else{
    //     linkWhatsapp = `https://web.whatsapp.com/send?phone=${whatsapp}&text=${messageWhatsApp}`
    // }

    const [showMobileMenu, setShowMobileMenu] = useState(false);

    let menu, menuMask;

    const bookNowMobile = 
        {
            title: "Agendar horário",
            link: "https://agendamento.salaovip.com.br/?slug=azbarbearia&template=1&portal=1" 
        }
    

    if (showMobileMenu){
        menu = <MenuMobile>
            <p>Já recebeu um atendimento especializado HOJE?</p>
            <ul>
                <li>
                    <a
                    href={bookNowMobile.link}
                    target="_blank"
                    rel="noreferrer">                        
                        {bookNowMobile.title.toUpperCase()}
                    </a>
                </li>

                {menuData.map((item, index) =>(
                    <li key={index}>
                        <ScrollLink
                         to={item.link}
                         spy={true}
                         smooth={true}
                         offset={-120}
                         duration={500}
                         onClick={() => setShowMobileMenu(false)}
                        >                        
                            {item.title.toUpperCase()}
                        </ScrollLink>
                    </li>
                ))}
            </ul>
            <footer>
                <a 
                href={instagram}
                target="_blank"
                rel="noreferrer"
                >
                    <InstagramIcon />
                </a>
                <a
                href={facebook}
                target="_blank"
                rel="noreferrer"
                >
                    <FacebookIcon />
                </a>
            </footer>
        </MenuMobile>

        menuMask = <MenuMobileMask onClick={() => setShowMobileMenu(false)} />
    }

    return (
        <HeaderContainer>
            <Nav>
                <LogoImg />
                <MenuIcon
                    onClick={() => setShowMobileMenu(!showMobileMenu)}
                />
                <MenuItems>
                    {menuData.map((item, index) => (
                        <li key={index}>
                            <ScrollLink
                            to={item.link}
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}>
                                {item.title.toUpperCase()}
                            </ScrollLink>
                        </li>
                    ))}
                    <a 
                    href={instagram}
                    target="_blank"
                    rel="noreferrer">
                        <InstagramIcon />
                    </a>
                    <a
                    href={facebook}
                    target="_blank"
                    rel="noreferrer">
                        <FacebookIcon />
                    </a>
                </MenuItems>
            </Nav>

            {menuMask}      

            {menu}

        </HeaderContainer>
    )
}
