import React from 'react'
import { useState } from 'react';
import WhatsappContactIcon from "../../assets/svg/whatsapp.svg";

import useSiteMetadata from '../../hooks/use-site-metadata';

import { AvecScheduleItem, WhatsAppContactContainer, WhatsAppContactItem } from "./styles";

import SchedulingModal from '../SchedulingModal';

export default function WhatsAppContact({isMobile}) {
  const { whatsapp } = useSiteMetadata().social;
  const messageWhatsApp = "Olá! Gostaria de conhecer os serviços da casa.";
  let linkWhatsapp;
  if (isMobile) {
    linkWhatsapp = `whatsapp://send?phone=${whatsapp}&text=${messageWhatsApp}`;
  }else{
    linkWhatsapp = `https://web.whatsapp.com/send?phone=${whatsapp}&text=${messageWhatsApp}`;
  }

  const linkAgendamentoAvec = "https://agendamento.salaovip.com.br/?slug=azbarbearia&template=1&portal=1"

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(prevState => !prevState); //acessa com o estado anterior e altera
  }

  return (
      <WhatsAppContactContainer>
        <WhatsAppContactItem
          href={linkWhatsapp}
          target="_blank"
          rel="noreferrer"
        >
          <WhatsappContactIcon />
        </WhatsAppContactItem>
        <AvecScheduleItem
          onClick={isMobile ? null : toggleModal}
          href={isMobile ? linkAgendamentoAvec : null}
          target={isMobile ? "_blank" : null}
          rel={isMobile ? "noreferrer" : null}
        >
          Agendar agora
        </AvecScheduleItem>
        <SchedulingModal 
          showModal={showModal}
          setShowModal={setShowModal}
          srcIFrame={linkAgendamentoAvec} 
        />
      </WhatsAppContactContainer>
  )
}
