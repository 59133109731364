import React from 'react';
import { ApplyButton, ResumeImage, WorkWithUsContainer } from './styles';

import useSiteMetadata from '../../hooks/use-site-metadata';

function WorkWithUs({ isMobile }) {
  const { whatsapp } = useSiteMetadata().social;
  const messageWhatsApp = "Olá, gostaria de saber mais informações de como trabalhar na AZ Barbearia!"
  let linkWhatsapp;
  if (isMobile) {
    linkWhatsapp = `whatsapp://send?phone=${whatsapp}&text=${messageWhatsApp}`
  }else{
    linkWhatsapp = `https://web.whatsapp.com/send?phone=${whatsapp}&text=${messageWhatsApp}`
  }

  return (
    <WorkWithUsContainer>
      <span>Trabalhe conosco</span>
      <h1>Queremos você em nossa equipe!</h1>
      <p>Estamos sempre disponíveis para receber você em nossa equipe! Não são necessários anos de experiência para trabalhar com a gente. Aqui na AZ Barbearia você irá conseguir desempenhar o seu trabalho com uma ótima infraestrutura para seus clientes.
      <br />
      <br />
      Nossa busca é por profissionais que desejam ascender no mercado e estão dispostos à absorver e repassar conhecimentos do setor da beleza.
      <br />
      <br />
      Quais profissões a AZ contrata?
      <br />
      - Barbeiro(a) <br />
      - Esteticista <br />
      - Massagista <br/>
      - Manicure / Pedicure <br />
      
      <br />        
      
      <ResumeImage />
      </p>
      <ApplyButton href={linkWhatsapp} target="_blank" rel="noreferrer">Envie seu currículo aqui</ApplyButton>
    </WorkWithUsContainer>
  );
}

export default WorkWithUs;