import styled from 'styled-components';

export const ExclusivesContainer = styled.div.attrs(() =>({
  name: 'Exclusives'
}))`
  width: 100vw;
  max-width: 100%;
  background: ${props => props.theme.colors.light};

  @media screen and (min-width: 768px){
    padding: 0 calc(((100vw - 1170px) / 2));
  }
  
  h1{
    padding: 30px 15px;
    font-family: ${props => props.theme.fonts.title};
    font-size: 32px;
    font-weight: 600;
    color: ${props => props.theme.colors.dark};
  }
`
export const CardsSection = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 15px;
  align-items: center;
  
  @media screen and (min-width: 768px){
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 10px; 
    justify-content: space-between;
    align-items: stretch;
    
  }
`

export const Card = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 250px;  
  
  align-items: center;
  justify-content: space-between;

  background: ${props => props.theme.colors.background};

  font-family: ${props => props.theme.fonts.text};
  
  border-radius: 5px;

  margin-bottom: 10px;

  @media screen and (min-width: 768px){
    max-width: 250px;
  }

  header{
    width: 100%;
    position: relative;
    
    justify-content: center;
    text-align: center;
    padding-top: 15px;
    

    border-radius: 5px 5px 0px 0px;

    svg{
      width: 50px;
      height: 50px;
    }
    
  }

  ul{
    width: 100%;
    padding: 5px 2px;
    
    justify-content: center;
    text-align: center;

    color: ${props => props.theme.colors.light};
    font-weight: 400;

    list-style-type: none;

  }

  footer{
    width: 100%;
    min-height: 50px;
    padding: 0 5px;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    background: ${props => props.theme.colors.primary}; 

    border-radius: 0px 0px 5px 5px;

    span {
      color: ${props => props.theme.colors.dark};
      font-weight: 600;
    }

  }

`