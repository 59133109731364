import styled from 'styled-components';

import resume from '../../assets/svg/resume.svg';

export const WorkWithUsContainer = styled.div.attrs(() => ({
  name: 'WorkWithUs'
}))`
  display: flex;
  width: 100vw;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;

  background: ${props => props.theme.colors.darkLight};
  color: ${props => props.theme.colors.dark};

  @media screen and (min-width: 768px){
    padding: 30px calc(((100vw - 1170px) / 2));
  }

  span{
    background: ${props => props.theme.colors.primary};
    
    font-family: ${props => props.theme.fonts.title};
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;


  }

  h1{
    padding: 30px;

    font-family: ${props => props.theme.fonts.title};
    font-weight: 600;
    font-size: 32px;
    line-height: 35px;
    text-align: center;
  }

  p{
    font-family: ${props => props.theme.fonts.text};
    font-weight: 400;

    padding: 15px;

    @media screen and (min-width: 768px){
      display: flex;
      justify-content: space-between;
      align-self: flex-start;
    }

  }

`

export const ResumeImage = styled(resume)`
  display: none;

  @media screen and (min-width: 768px){
    min-width: 378px;

    display: flex;
    padding: 15px;
  }
` 

export const ApplyButton = styled.a`
  padding: 15px 15px;
  
  font-family: ${props => props.theme.fonts.text};
  color: ${props => props.theme.colors.dark};
  font-size: 16px;
  font-weight: 400;
  border-radius: 5px;
  background: ${props => props.theme.colors.light};
  transition: background-color 0.5s;
  text-decoration: none;
  cursor: pointer;

  &:hover{
    background: ${props => props.theme.colors.primary};
  }
`