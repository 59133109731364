import React from 'react';
import { AboutUsContainer } from './styles';

function AboutUs() {
  return (
    <AboutUsContainer>
      <h1>Você está em boas mãos</h1>
      {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas id elit sagittis, imperdiet ex a, convallis arcu. Phasellus non facilisis nulla. Phasellus vehicula sagittis libero, a posuere risus lobortis eu. Integer commodo vel felis ut lobortis. Nullam sagittis justo vel nunc aliquet rhoncus. Sed nec suscipit lectus, vel mattis ipsum. Maecenas iaculis odio ac mi interdum auctor.</p> */}
    </AboutUsContainer> 
  );
}

export default AboutUs;