import styled from 'styled-components';

import { GatsbyImage } from 'gatsby-plugin-image';

export const HeroContainer = styled.div`
  width: 100vw;
  max-width: 100%;
  height: calc(100vh - 120px);

  color: ${props => props.theme.colors.light};
  

  @media screen and (min-width: 768px){
      height: calc(100vh - 150px);
      padding: 0 calc(((100vw - 1170px) / 2));

      background: ${props => props.theme.colors.background};

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
  }

`

export const BgImage = styled(GatsbyImage)`
  position: absolute !important;
  top: 120px;
  left: 0;
  width: 100%;
  z-index: 0;
  height: calc(100vh - 120px);
  backface-visibility: hidden;

  & > img {
    animation: 2s fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;
    font-family: 'object-fit: cover !important; object-position: 0% 0% !important;' // needed for IE9+ polyfill
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }

  &&:before {
    content: '';
    position:absolute !important;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0) 100%);
    z-index: 1;
  }

  @media screen and (min-width: 768px){
    position: relative !important;
    align-self: center;    
    height: 90%;
    width: calc(100% - 15px);

    top: 0;

    & > img {
      margin-right: 15px;
    }

    &&:before{
      display: none !important;
    }
  }
`

export const ImpactPhrase = styled.div`
  font-family: ${props => props.theme.fonts.title};
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-top: 10%;
  z-index: 2;

  p{
    width: 70%;
    line-height: 40px;
    font-weight: 500;
    font-size: 32px;
    
    span{
     color: ${props => props.theme.colors.primary}
    }
  }

  a{
    background-color: ${props => props.theme.colors.primary};
    transition: background-color 0.2s;
    width: 80%;
    max-width: 300px;
    border-radius: 5px;
    margin-top: 25%;
    display: inline-block;

    display: none;
    align-items: center;
    justify-content: space-evenly;

    padding: 20px 5px;

    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    color: ${props => props.theme.colors.dark};
    text-decoration: none;

    cursor: pointer;
    
    &:hover{
      background-color: ${props => props.theme.colors.light};
    }

    svg {
      margin: 0 5px;
    }
  }

  @media screen and (min-width: 768px){
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: 1fr;   
    justify-content: space-between;

    align-items: center;

    p{
      width: 100%;
      font-weight: 500;
      font-size: 36px;
      line-height: 39px;
    }

    a{
      margin-top: 0;      
      max-height: 60px;

      align-self: center;
    }
  }
`

