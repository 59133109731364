import React, { useState, useEffect } from "react"

import { Container } from './styles'

import Header from '../components/Header'
import Hero from '../components/Hero'
import YellowBar from "../components/YellowBar"
import Exclusives from "../components/Exclusives"
import AboutUs from "../components/AboutUs"
import InstagramFeed from "../components/InstagramFeed"
import WorkWithUs from "../components/WorkWithUs"
import Contact from "../components/Contact"
import WhatsAppContact from "../components/WhatsAppContact"
import Seo from "../components/seo"

import { isMobile } from "react-device-detect"; //true or false

export default function Home() {
  const [mobile, setMobile] = useState();

  useEffect(() => {
    setMobile(isMobile);
  }, [setMobile]);

  return (
    <Container>
      <Seo title="Home" />
      <Header />
      <Hero />
      <YellowBar />
      <Exclusives />
      <AboutUs />
      <InstagramFeed />
      <WorkWithUs isMobile={mobile} />
      <Contact />
      <WhatsAppContact isMobile={isMobile} />
    </Container>
  );
}
