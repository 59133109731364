const menuData = [
    {
        title: "Serviços",
        link: "Exclusives"
    },
    {
        title: "Sobre",
        link: "AboutUs"
    },
    {
        title: "Trabalhe conosco",
        link: "WorkWithUs"
    },
    {
        title: "Contato",
        link: "Contact"
    },
]

export default menuData;