import React from 'react';
import useInstagramFeed from '../../hooks/useInstagramFeed';
import useSiteMetadata from '../../hooks/use-site-metadata';
import { InstagramFeedCarrousel, InstagramFeedContainer, InstragramFeedCarrouselImage } from './styles';
import {GatsbyImage, getImage} from 'gatsby-plugin-image'

function InstagramFeed() {
  const { instagram } = useSiteMetadata().social;

  return(
    <InstagramFeedContainer>
      <InstagramFeedCarrousel>
        {useInstagramFeed().map((item, index) => (
        item.node.localImage.childImageSharp && ( // verify if it's an image, if not it does not have 'localImage'
          <InstragramFeedCarrouselImage
          key={index}
          >
            <a href={instagram} target="_blank" rel="noreferrer">
              <GatsbyImage
                image={getImage(item.node.localImage)}
                alt={item.node.caption || "Instagram Post"}
              />
            </a>
           
          </InstragramFeedCarrouselImage>
        )
      ))}
      </InstagramFeedCarrousel>
    </InstagramFeedContainer>
  ) 

    
}

export default InstagramFeed;