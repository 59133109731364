import { useStaticQuery, graphql } from "gatsby"

const useInstagramFeed = () => {
  const data = useStaticQuery(graphql`
    {
      allInstagramContent{
        edges {
          node {
            caption
            media_url
            localImage {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1
                  placeholder: BLURRED
                  quality: 90
                  formats: WEBP
                  width: 576
                )
              }
            }
          }
        }
      }
    }
  `)
  return data.allInstagramContent.edges;
}

export default useInstagramFeed;

