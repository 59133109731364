import styled from 'styled-components';

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: ${props => props.theme.colors.background};
  opacity: 0.8;
`

export const SchedulingModalContainer = styled.div`
  width: 500px;
  height: 400px;
  background: #fff;

  position: fixed;
  align-self: center;
  top: 150px;

  border-radius: 10px;

  display: block !important;
  z-index: 9;
`

export const ModalIFrame = styled.iframe`
  width: 100%;
  height: 100%;

  border-radius: 10px;
`

export const ModalCloseButton = styled.span`
  position: absolute;
  top: 0px;
  right: -40px;
  
  padding-top: 15px;
  text-justify: center;
  text-align: center;

  width: 50px;
  height: 50px;

  font-family: ${props => props.theme.fonts.title};
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;

  color: ${props => props.theme.colors.background};
  border-radius: 50%;
  background: ${props => props.theme.colors.light};
  transition: background-color 0.3s;

  z-index: 10;

 &:hover{
   background: ${props => props.theme.colors.dark}
 }

`