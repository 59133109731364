import { useStaticQuery, graphql } from "gatsby"

const useSlideShowImage = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {relativeDirectory: {eq: "slideshow"}}
        sort: {order: ASC, fields: name}
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                blurredOptions: {width: 100}
                transformOptions: {cropFocus: CENTER, fit: COVER}
                quality: 90
                width: 600
                formats: WEBP
                aspectRatio: 0
              )
            }
          }
        }
      }
    }
  `)
  return data.allFile.edges;
}

export default useSlideShowImage

