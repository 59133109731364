import { graphql, useStaticQuery } from "gatsby"

export default function useSiteMetadata() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          author
          credits
          description
          siteUrl
          title
          social {
            whatsapp
            facebook
            instagram
          }
        }
      }
    }
  `)
  return data.site.siteMetadata
}