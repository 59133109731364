import styled from 'styled-components'

import logo from '../../assets/svg/logo.svg'
import menu from '../../assets/svg/menu.svg'
import facebook from '../../assets/svg/facebook.svg'
import instagram from '../../assets/svg/instagram.svg'

export const HeaderContainer = styled.header`
    width: 100vw;
    max-width: 100%;
    height: 120px;
    z-index: 100;
    
    align-items: center;

    background: ${props => props.theme.colors.background};

    position: sticky;
    top: 0;

    @media screen and (min-width: 768px){
        height: 150px;

        padding: 0.5rem calc(((100vw - 1170px) / 2));
        position: relative;
    }
`

export const Nav = styled.nav`
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding:0.5rem 15px;
`

export const LogoImg = styled(logo)`
    width: 128px;
    height: 100px;

    @media screen and (min-width: 768px){
        width: 188px;
        height: 147px;
    }
`

export const MenuIcon = styled(menu)`
    width: 40;
    height: 30px;
    cursor: pointer;

    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-15px, 75%);

    fill: ${props => props.theme.colors.light};

    &:hover{
        fill: ${props => props.theme.colors.primary};
    }

    @media screen and (min-width: 768px){
        display: none;
    }
`

export const MenuItems = styled.ul`
    display: none;

    /* para Desktop */
    @media screen and (min-width: 768px){ 
        display: flex;
        justify-content: space-between;
        display: inline-block;
        align-items: center;
        
        list-style:none;
        padding: 0;

        li{
            display: inline;
            padding-right: 20px;
            
        }
        
        li a{
            text-decoration: none;

            font-family: ${props => props.theme.fonts.title};
            font-weight: 600;
            font-size: 16px;
            line-height: 17px;
            color: ${props => props.theme.colors.light};

            display: inline-block;
            transition: color .4s;

            cursor: pointer;

        
            &:hover{
                color: ${props => props.theme.colors.primary};           
            }
        }
    }
`
export const SocialIcons = styled.div`
    display: none;

    @media screen and (min-width: 768px){
        display: flex;
        justify-content: center;
    }
`

export const InstagramIcon = styled(instagram)`
    width: 32px;
    height: 32px;
    cursor: pointer;

    &:hover{
        fill: ${props => props.theme.colors.primary};
    }
`

export const FacebookIcon = styled(facebook)`
    width: 22px;
    height: 22px;

    &:hover{
        fill: ${props => props.theme.colors.primary};
    }
    
`
export const MenuMobileMask = styled.div`
    position: fixed;
    background: transparent;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    /* box-shadow: 5px 20px 30px 10px rgba(0,0,0,0.8); */

    @media screen and (min-width: 768px){
        display: none;
    }

`
export const MenuMobile = styled.div`
    position: relative;
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.light};
    top: 0;
    left: 0;
    width: 80%;
    height: calc(100vh - 120px);
    /* box-shadow: 5px 20px 30px 10px rgba(0,0,0,0.8); */
    display: flex;
    text-align: center;
    flex-direction: column;

    p {
        padding: 15px;
        font-family: ${props => props.theme.fonts.text};
        font-size: 18px;
    }

    ul{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 80%;
        width: 100%;

        list-style-type: none;

        li{
            display: list-item;
            width: 100%;

            padding: 10px 5px;
    

            &:first-child{
                background: ${props => props.theme.colors.primary};
                a{
                    color: ${props => props.theme.colors.dark};   
                }
            }   
        }
        
        li a{
            text-decoration: none;

            font-family: ${props => props.theme.fonts.title};
            font-weight: 600;
            font-size: 24px;
            line-height: 26px;
            color: ${props => props.theme.colors.light};

            display: inline-block;
            transition: color .4s;

            cursor: pointer;
        }
    }

    footer{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 15px;

        a{
            margin-bottom: 30px;
        }


    }

    @media screen and (min-width: 768px){
        display: none;
    }

`
