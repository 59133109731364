import React, { useState } from 'react';
import SchedulingModal from '../SchedulingModal/index.js';

// import useSiteMetadata from '../../hooks/use-site-metadata';

import { SchedulingButton, CalendarIcon, YellowBarContainer } from './styles.js';

function YellowBar({ isMobile }) {
  // const { whatsapp } = useSiteMetadata().social;
  // const messageWhatsApp = "Olá! Gostaria de conhecer os serviços da casa."
  // let linkWhatsapp;
  // if (isMobile) {
  //   linkWhatsapp = `whatsapp://send?phone=${whatsapp}&text=${messageWhatsApp}`
  // }else{
  //   linkWhatsapp = `https://web.whatsapp.com/send?phone=${whatsapp}&text=${messageWhatsApp}`
  // }

  const linkAgendamentoAvec = "https://agendamento.salaovip.com.br/?slug=azbarbearia&template=1&portal=1"

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(prevState => !prevState); //acessa com o estado anterior e altera
  }
  
  return (
    <YellowBarContainer>
      <p>Já recebeu um tratamento especial HOJE? Ainda não?</p>
        <SchedulingButton
        onClick={isMobile ? null : toggleModal}
        href={isMobile ? linkAgendamentoAvec : null}
        target={isMobile ? "_blank" : null}
        rel={isMobile ? "noreferrer" : null}>
          <CalendarIcon /> 
          Agendar agora
        </SchedulingButton>
        <SchedulingModal 
          showModal={showModal}
          setShowModal={setShowModal}
          srcIFrame={linkAgendamentoAvec} 
        />        
    </YellowBarContainer>
  );
}

export default YellowBar;