import React from 'react';

import { ExclusivesContainer, Card, CardsSection } from './styles'

import SpecialsIcon from '../../assets/svg/specials.svg';
import BeardIcon from '../../assets/svg/beard.svg';
import HairIcon from '../../assets/svg/hair.svg';
import BeautyIcon from '../../assets/svg/beauty.svg';

const cardsContents = [
  {
    icon: <SpecialsIcon />,
    text: ["Depilações", "Massagens", "Drenagens", "Escalda pés", "Ventosas", "Manicure", "Pedicure"],
    name: 'Tratamentos especiais'
  },
  {
    icon: <BeardIcon />,
    text: ["Barboterapia", "Barba completa", "Pigmentação barba"],
    name: 'Barba'
  },
  {
    icon: <HairIcon />,
    text: ["Corte de cabelo", "Penteado", "Pigmentação", "Platinado"],
    name: 'Cabelo'
  },
  {
    icon: <BeautyIcon />,
    text: ["Limpeza de pele", "Hidratação facial", "Design de sobrancelha", "Liberação miofacial"],
    name: 'Estética'
  },
]

function Exclusives() {
  return(
    <ExclusivesContainer>
      <h1>Exclusivos para você</h1>
      <CardsSection>
        {cardsContents.map((card, index) => {
          return(
            <Card key={index}>
              <header>{card.icon}</header>
              <ul>
                {card.text.map((item, index) => {
                  return <li key={index}> - {item} </li>
                })}
              </ul>
              <footer><span>{card.name}</span></footer>
            </Card>
          )
        })}
      </CardsSection>
    </ExclusivesContainer>
  );
}

export default Exclusives;