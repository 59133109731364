import React, { lazy, Suspense } from 'react';
import { ContactContainerTextContent, ContactContainerTitle, ContactContainerFooterContent, LocationMap, ContactContainer , ContactWrap} from './styles';
const MapContact = lazy(() => import("../../hooks/useIframeGoogleMaps"));
// import useGoogleMaps from "../../hooks/useGoogleMaps";

function Contact() {
  const isSSR = typeof window === "undefined"

  return (
    <ContactContainer>
      <ContactContainerTitle>Contato</ContactContainerTitle>
        <ContactWrap>
          <ContactContainerTextContent>
            <span>Telefone: </span>(44) 3226-0405 <br />
            <span>WhatsApp: </span>(44) 9 8822-4571 <br />
            <br />
            Av. Herval, 844 - Sala 01 - Zona 7, 87020-016, Maringá - PR <br/>
            <br />
            Funcionamento de SEGUNDA à SEXTA das 09:00 às 20:00hrs. <br />
            Aos SÁBADOS das 09:00 às 15:00hrs. <br />
          </ContactContainerTextContent>
          <LocationMap>
            {/* {useGoogleMaps()} */}
            {!isSSR && (
              <Suspense fallback={<div />}>
               <MapContact /> 
             </Suspense>
            )}
          </LocationMap>
        </ContactWrap>
      <ContactContainerFooterContent>
          <footer>
            © Copyright 2021 – Todos os direitos reservados. <br />
            Desenvolvido por Matheus Mazzola
          </footer>
        </ContactContainerFooterContent>
    </ContactContainer>

  );
}

export default Contact;